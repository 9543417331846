import React from "react";
import Hero from "../components/Home/Hero";
import Choose from "../components/Home/Choose";
import './styles/home.css'
import Bonuses from "../components/Home/Bonuses";
const Home = () => {
  return (
    <div>
      <Hero />
      <Choose/>
      {/* <Bonuses/> */}
    </div>
  );
};

export default Home;

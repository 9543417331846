import React from "react";
import "../Home/home.css";
const Hero = () => {
  return (
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-8bdf8af elementor-section-boxed elementor-section-height-default elementor-section-height-default hero-banner"
      data-id="8bdf8af"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div class="elementor-background-overlay bg-overlay"></div>
      <div class="elementor-container elementor-column-gap-default">
        <div
          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-62763d1d"
          data-id="62763d1d"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-585ff9f1 elementor-widget elementor-widget-heading"
              data-id="585ff9f1"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div class="elementor-widget-container">
                <h1 class="elementor-heading-title elementor-size-default hero-title">
                  JILIKO the leading online casino in the Philippines
                </h1>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-58246d2b elementor-widget elementor-widget-text-editor "
              data-id="58246d2b"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div class="elementor-widget-container text-white w-100">
                Jiliko casino is a leading online gambling brand in the
                Philippines. Jiliko has built up a reputation for being one of
                the most trusted and secure online casinos. We have some of the
                best payout rates in the industry, and we will do everything we
                can to ensure that your experience is as pleasant and
                trouble-free as possible.
              </div>
            </div>
            <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-3af4bbb5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="3af4bbb5"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-31569e1c w-100"
                  data-id="31569e1c"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-466f47f elementor-align-justify elementor-widget elementor-widget-button"
                      data-id="466f47f"
                      data-element_type="widget"
                      data-widget_type="button.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="elementor-button-wrapper">
                          <a
                            href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                            class="elementor-button-link elementor-button elementor-size-lg  "
                            role="button"
                          >
                            <span class="elementor-button-content-wrapper">
                              <span class="elementor-button-text ">
                                view our portfolio
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5a2506e1 w-100"
                  data-id="5a2506e1"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-257f4bec elementor-align-justify elementor-widget elementor-widget-button"
                      data-id="257f4bec"
                      data-element_type="widget"
                      data-widget_type="button.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="elementor-button-wrapper">
                          <a
                            href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                            class="elementor-button-link elementor-button elementor-size-lg"
                            role="button"
                          >
                            <span class="elementor-button-content-wrapper">
                              <span class="elementor-button-text">
                                get a quote
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2b754a2e elementor-hidden-tablet elementor-hidden-phone"
                  data-id="2b754a2e"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-431df9fa elementor-hidden-tablet elementor-hidden-phone"
          data-id="431df9fa"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap"></div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

import React from "react";

const NoPages = () => {
  return (
    <div>
      <div className="noPage d-flex justify-content-center align-items-center">No Page Found!</div>
    </div>
  );
};

export default NoPages;

import React from "react";

const Chooselist = () => {
  return (
    <div>
      {" "}
      <section
        class="elementor-section elementor-inner-section elementor-element elementor-element-59451a80 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="59451a80"
        data-element_type="section"
      >
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
            class="elementor-element elementor-element-dfa01d2 elementor-widget elementor-widget-heading"
            data-id="dfa01d2"
            data-element_type="widget"
            data-widget_type="heading.default"
          ></div>
          <section
            class="elementor-section elementor-inner-section elementor-element elementor-element-59451a80 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="59451a80"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-5c197efe animated fadeIn"
                data-id="5c197efe"
                data-element_type="column"
                data-settings='{"animation":"fadeIn","animation_delay":250}'
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-f4229f8 elementor-view-default elementor-widget elementor-widget-icon"
                    data-id="f4229f8"
                    data-element_type="widget"
                    data-widget_type="icon.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-icon-wrapper">
                        <div class="elementor-icon">
                          <i aria-hidden="true" class="fas fa-star"></i>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-27b19604 elementor-widget elementor-widget-heading"
                    data-id="27b19604"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h5 class="elementor-heading-title elementor-size-default">
                        Entertainment
                      </h5>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-348ec61 elementor-widget elementor-widget-text-editor"
                    data-id="348ec61"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      Our casino is designed to offer you the best casino gaming
                      experience. You can find the best quality casino games
                      here, whether it’s slots, live casino, fish shooting
                      games, card table games or sports betting, sabong betting,
                      you can find it all at jiliko.{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-fe51eff animated fadeIn"
                data-id="fe51eff"
                data-element_type="column"
                data-settings='{"animation":"fadeIn","animation_delay":250}'
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-7a84440 elementor-view-default elementor-widget elementor-widget-icon"
                    data-id="7a84440"
                    data-element_type="widget"
                    data-widget_type="icon.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-icon-wrapper">
                        <div class="elementor-icon">
                          <i aria-hidden="true" class="fas fa-mobile-alt"></i>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-20d094b3 elementor-widget elementor-widget-heading"
                    data-id="20d094b3"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h5 class="elementor-heading-title elementor-size-default">
                        Convenience
                      </h5>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-1585cfa elementor-widget elementor-widget-text-editor"
                    data-id="1585cfa"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <p>
                        jiliko bet made it easy for you to get all the
                        entertainment you need, right on your mobile or tablet.
                        Through our mobile app, you can play all the games from
                        your favorite casinos directly from your smartphone or
                        tablet. You can play anytime, anywhere.
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-ab09fb4 animated fadeIn"
                data-id="ab09fb4"
                data-element_type="column"
                data-settings='{"animation":"fadeIn","animation_delay":250}'
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-82b7cec elementor-view-default elementor-widget elementor-widget-icon"
                    data-id="82b7cec"
                    data-element_type="widget"
                    data-widget_type="icon.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-icon-wrapper">
                        <div class="elementor-icon">
                          <i aria-hidden="true" class="fas fa-trophy"></i>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-6d26872f elementor-widget elementor-widget-heading"
                    data-id="6d26872f"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h5 class="elementor-heading-title elementor-size-default">
                        Professionality
                      </h5>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-bccc0b5 elementor-widget elementor-widget-text-editor"
                    data-id="bccc0b5"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      We are dedicated to providing the best possible gaming
                      experience. That’s why we hire only the most trustworthy
                      and friendly customer support officers. We are ready to
                      answer your questions 24 hours a day 7 days a week.{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-297b3b8d animated fadeIn"
                data-id="297b3b8d"
                data-element_type="column"
                data-settings='{"animation":"fadeIn","animation_delay":750}'
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-c247285 elementor-view-default elementor-widget elementor-widget-icon"
                    data-id="c247285"
                    data-element_type="widget"
                    data-widget_type="icon.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-icon-wrapper">
                        <div class="elementor-icon">
                          <i aria-hidden="true" class="fas fa-shield-alt"></i>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-5533e293 elementor-widget elementor-widget-heading"
                    data-id="5533e293"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h5 class="elementor-heading-title elementor-size-default">
                        Security
                      </h5>{" "}
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-b1299a3 elementor-widget elementor-widget-text-editor"
                    data-id="b1299a3"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <p>
                        We take the security of your personal information very
                        seriously and have 128 bit data encryption. jiliko.bet
                        is also certified by Isle of Man Gambling Supervision
                        Commission. We take all measures to ensure that your
                        information is always safe
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default Chooselist;

import "./App.css";
import "../src/styles/global.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/layout/MainLayout";
import Home from "./pages/Home";
import NoPage from "./pages/Nopage";
import Signup from "./pages/Signup";
import Slots from "./pages/Slots";
import Sabong from "./pages/Sabong";
import LiveGames from "./pages/LiveGames";
import SportsBetting from "./pages/SportsBetting";
import Signuplayout from "./components/layout/signuplayout";
function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              {/* <Route path="/sign-up" element={<Signup />} /> */}
              {/* <Route path="/Anotherpage" element={<Signup />} /> */}
              <Route path="/slots" element={<Slots />} />
              <Route path="/sabong" element={<Sabong />} />
              <Route path="/live-games" element={<LiveGames />} />
              <Route path="/sports-betting" element={<SportsBetting />} />
              <Route path="*" element={<NoPage />} />
            </Route>{" "}
            <Route element={<Signuplayout />}>
              <Route path="/sign-up" element={<Signup />} />
            
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

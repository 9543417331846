import React from "react";
import "../styles/navbar.css";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <header
      id="masthead"
      class="site-header"
      role="banner"
      itemtype="https://schema.org/WPHeader"
      itemscope
    >
      <div id="main-header" class="site-header-wrap">
        <div class="site-header-inner-wrap">
          <div class="site-header-upper-wrap">
            <div class="site-header-upper-inner-wrap">
              <div
                class="site-top-header-wrap site-header-row-container site-header-focus-item site-header-row-layout-standard"
                data-section="kadence_customizer_header_top"
              >
                <div class="site-header-row-container-inner">
                  <div class="site-container">
                    <div class="site-top-header-inner-wrap site-header-row site-header-row-has-sides site-header-row-no-center">
                      <div class="site-header-top-section-left site-header-section site-header-section-left"></div>
                      <div class="site-header-top-section-right site-header-section site-header-section-right">
                        <div
                          class="site-header-item site-header-focus-item"
                          data-section="kadence_customizer_header_button"
                        >
                          <div class="header-button-wrap">
                            <div class="header-button-inner-wrap">
                              <a
                                href="/sign-up"
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                class="button header-button button-size-medium button-style-filled"
                              >
                                Jiliko Login
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="site-header-item site-header-focus-item"
                          data-section="kadence_customizer_header_html"
                        >
                          <div class="header-html inner-link-style-normal">
                            <div class="header-html-inner">
                              <div class="header-button-wrap">
                                <div class="header-button-inner-wrap">
                                  <a
                                    class="button header-button button-size-medium button-style-filled"
                                    href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    target="_blank"
                                    rel="noopener noreferrer nofollow"
                                  >
                                    Jiliko free 100
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="site-main-header-wrap site-header-row-container site-header-focus-item site-header-row-layout-standard"
                data-section="kadence_customizer_header_main"
              >
                <div class="site-header-row-container-inner">
                  <div class="site-container">
                    <div class="site-main-header-inner-wrap site-header-row site-header-row-has-sides site-header-row-no-center">
                      <div class="site-header-main-section-left site-header-section site-header-section-left">
                        <div
                          class="site-header-item site-header-focus-item"
                          data-section="title_tagline"
                        >
                          <div class="site-branding branding-layout-standard">
                            <a
                              class="brand"
                              href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                              rel="home"
                              aria-label="JILIKO Bet 747live and Jili online casino in philippines"
                            >
                              <div class="site-title-wrap">
                                <p class="site-title">
                                  JILIKO Bet 747live and Jili online casino in
                                  philippines
                                </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="site-header-main-section-right site-header-section site-header-section-right">
                        <div
                          class="site-header-item site-header-focus-item site-header-item-main-navigation header-navigation-layout-stretch-false header-navigation-layout-fill-stretch-false"
                          data-section="kadence_customizer_primary_navigation"
                        >
                          <nav
                            id="site-navigation"
                            class="main-navigation header-navigation nav--toggle-sub header-navigation-style-standard header-navigation-dropdown-animation-none"
                            role="navigation"
                            aria-label="Primary Navigation"
                          >
                            <div class="primary-menu-container header-menu-container">
                              <ul id="primary-menu" class="menu">
                                <li
                                  id="menu-item-239"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-71 current_page_item menu-item-239"
                                >
                                  <NavLink to="/" aria-current="page">
                                    Home
                                  </NavLink>
                                </li>
                                <li
                                  id="menu-item-248"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-248"
                                >
                                  <NavLink to="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Sign up
                                  </NavLink>
                                </li>
                                <li
                                  id="menu-item-311"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-311"
                                >
                                  <NavLink to="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Slots
                                  </NavLink>
                                </li>
                                <li
                                  id="menu-item-312"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
                                >
                                  <NavLink to="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Sabong
                                  </NavLink>
                                </li>
                                <li
                                  id="menu-item-310"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-310"
                                >
                                  <NavLink to="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Live Games
                                  </NavLink>
                                </li>
                                <li
                                  id="menu-item-349"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-349"
                                >
                                  <NavLink to="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Sports Betting
                                  </NavLink>
                                </li>
                                <li
                                  id="menu-item-350"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-350"
                                >
                                  <NavLink to="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Fish Games
                                  </NavLink>
                                </li>
                                <li
                                  id="menu-item-375"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-375"
                                >
                                  <NavLink to="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Arcade Games
                                  </NavLink>
                                </li>
                                <li
                                  id="menu-item-447"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-447"
                                >
                                  <a href="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Promotion
                                  </a>
                                </li>
                                <li
                                  id="menu-item-456"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-456"
                                >
                                  <a href="https://bj88ph.live/af/Zd946zCP/jiliko-game">
                                    Banking
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mobile-header" class="site-mobile-header-wrap">
        <div class="site-header-inner-wrap">
          <div class="site-header-upper-wrap">
            <div class="site-header-upper-inner-wrap">
              <div class="site-top-header-wrap site-header-focus-item site-header-row-layout-standard site-header-row-tablet-layout-standard site-header-row-mobile-layout-default ">
                <div class="site-header-row-container-inner">
                  <div class="site-container">
                    <div class="site-top-header-inner-wrap site-header-row site-header-row-has-sides site-header-row-no-center">
                      <div class="site-header-top-section-left site-header-section site-header-section-left"></div>
                      <div class="site-header-top-section-right site-header-section site-header-section-right">
                        <div
                          class="site-header-item site-header-focus-item"
                          data-section="kadence_customizer_mobile_html"
                        >
                          <div class="mobile-html inner-link-style-normal">
                            <div class="mobile-html-inner">
                              <div
                                class="site-header-item site-header-focus-item"
                                data-section="kadence_customizer_mobile_button"
                              >
                                <div class="mobile-header-button-wrap">
                                  <div class="mobile-header-button-inner-wrap">
                                    <a
                                      class="button mobile-header-button button-size-medium button-style-filled"
                                      href="https://www.jiliko.vip/?pid=jiliko"
                                      target="_blank"
                                      rel="noopener noreferrer nofollow"
                                    >
                                      Jiliko Login
                                    </a>{" "}
                                    <a
                                      class="button mobile-header-button button-size-medium button-style-filled"
                                      href="https://www.jiliko.vip/?pid=jiliko"
                                      target="_blank"
                                      rel="noopener noreferrer nofollow"
                                    >
                                      Jiliko free 100
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="site-main-header-wrap site-header-focus-item site-header-row-layout-standard site-header-row-tablet-layout-default site-header-row-mobile-layout-default ">
                <div class="site-header-row-container-inner">
                  <div class="site-container">
                    <div class="site-main-header-inner-wrap site-header-row site-header-row-has-sides site-header-row-no-center">
                      <div class="site-header-main-section-left site-header-section site-header-section-left">
                        <div
                          class="site-header-item site-header-focus-item"
                          data-section="title_tagline"
                        >
                          <div class="site-branding mobile-site-branding branding-layout-standard branding-tablet-layout-standard branding-mobile-layout-standard">
                            <a
                              class="brand"
                              href="https://jiliko.com.ph/"
                              rel="home"
                              aria-label="JILIKO Bet 747live and Jili online casino in philippines"
                            >
                              <div class="site-title-wrap">
                                <div class="site-title vs-sm-false">
                                  JILIKO Bet 747live and Jili online casino in
                                  philippines
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="site-header-main-section-right site-header-section site-header-section-right">
                        <div
                          class="site-header-item site-header-focus-item site-header-item-navgation-popup-toggle"
                          data-section="kadence_customizer_mobile_trigger"
                        >
                          <div class="mobile-toggle-open-container">
                            <button
                              id="mobile-toggle"
                              class="menu-toggle-open drawer-toggle menu-toggle-style-default"
                              aria-label="Open menu"
                              data-toggle-target="#mobile-drawer"
                              data-toggle-body-class="showing-popup-drawer-from-right"
                              aria-expanded="false"
                              data-set-focus=".menu-toggle-close"
                            >
                              <span class="menu-toggle-icon">
                                <span class="kadence-svg-iconset">
                                  <svg
                                    aria-hidden="true"
                                    class="kadence-svg-icon kadence-menu-svg"
                                    fill="currentColor"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <title>Toggle Menu</title>
                                    <path d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path>
                                  </svg>
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar;

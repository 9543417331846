import React from "react";
import  '../styles/footer.css'
const Footer = () => {
  return (
    <footer id="colophon" class="site-footer footer" role="contentinfo">
      <div class="site-footer-wrap">
        <div
          class="site-bottom-footer-wrap site-footer-row-container site-footer-focus-item site-footer-row-layout-standard site-footer-row-tablet-layout-default site-footer-row-mobile-layout-default"
          data-section="kadence_customizer_footer_bottom"
        >
          <div class="site-footer-row-container-inner">
            <div class="site-container">
              <div class="site-bottom-footer-inner-wrap site-footer-row site-footer-row-columns-1 site-footer-row-column-layout-row site-footer-row-tablet-column-layout-default site-footer-row-mobile-column-layout-row ft-ro-dir-row ft-ro-collapse-normal ft-ro-t-dir-default ft-ro-m-dir-default ft-ro-lstyle-plain">
                <div class="site-footer-bottom-section-1 site-footer-section footer-section-inner-items-1">
                  <div
                    class="footer-widget-area site-info site-footer-focus-item content-align-default content-tablet-align-default content-mobile-align-default content-valign-default content-tablet-valign-default content-mobile-valign-default"
                    data-section="kadence_customizer_footer_html"
                  >
                    <div class="footer-widget-area-inner site-info-inner">
                      <div class="footer-html inner-link-style-normal">
                        <div class="footer-html-inner">
                          <p>
                            &copy; 2023 JILIKO Bet 747live and Jili online
                            casino in philippines
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import "../Home/home.css";

const Bonuses = () => {
  return (
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-65aab14 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="65aab14"
      data-element_type="section"
   
    >
      <div class="elementor-container elementor-column-gap-default">
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-189f0b56"
          data-id="189f0b56"
          data-element_type="column"
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-bed320a elementor-widget elementor-widget-heading"
              data-id="bed320a"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div class="elementor-widget-container">
                <h3 class="elementor-heading-title elementor-size-default join-jiliko">
                  Join jiliko today and claim the best bonuses!
                </h3>
              </div>
              {/* <h3 className="text-white"></h3> */}
            </div>
            <div
              class="elementor-element elementor-element-1fb59d35 elementor-widget elementor-widget-text-editor"
              data-id="1fb59d35"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div class="elementor-widget-container">
                <p>
                  At jiliko, we have numerous bonuses for players, whether you
                  are a new member or a returning member, so you can take
                  advantage of our best promotions and use them to take your
                  gaming experience to the next level. Join jiliko today to
                  receive the best bonuses like Jili Free 100!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bonuses;

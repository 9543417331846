import React from "react";

const Signup = () => {
  return (
    <div className="noPage d-flex justify-content-center align-items-center ">
    
    </div>
  );
};

export default Signup;

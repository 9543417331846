import React from "react"
// import Header from "../header";
// import  Footer  from "../footer";
import { Outlet } from "react-router";
import NavBar from "../Header/NavBar";
import Footer from "../Footer/Footer";


const Layout = () => {
  return (
    <>
      {/* <Header /> */}
      <NavBar/>
      {/* <SideNav/> */}
      <Outlet />
      <Footer/>
    </>
  );
};

export default Layout;
// export default Layout;
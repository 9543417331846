import React from "react";
import "../Home/home.css";

const OnlineSabong = () => {
  return (
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-25c4d4a elementor-section-boxed elementor-section-height-default elementor-section-height-default "
      data-id="25c4d4a"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-default">
        <div
          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1c16031"
          data-id="1c16031"
          data-element_type="column"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-widget-wrap elementor-element-populated">
            <section
              class="elementor-section elementor-inner-section elementor-element elementor-element-57a36ad elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="57a36ad"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default online-sabong-bg">
                <div
                  class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e1c29f5"
                  data-id="e1c29f5"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated ">
                    <div
                      class="elementor-element elementor-element-28ffbb7 elementor-widget elementor-widget-heading"
                      data-id="28ffbb7"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h3 class="elementor-heading-title elementor-size-default">
                          Online sabong
                        </h3>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-99544b8 elementor-widget elementor-widget-text-editor"
                      data-id="99544b8"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container">
                        Sabong (cockfighting) is a native Philippine sport that
                        is practiced in many Asian countries and in some parts
                        of the United States. It involves two rooster fighters,
                        that are trained for fighting through training and
                        conditioning. Our online sabong betting feature is one
                        of the most fun and exciting features in Philippines,
                        consider visiting our online sabong today to see what we
                        can offer!{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7306859 elementor-hidden-tablet elementor-hidden-phone"
                  data-id="7306859"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-60a63da elementor-widget elementor-widget-image"
                      data-id="60a63da"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div class="elementor-widget-container">
                        <img
                          decoding="async"
                          loading="lazy"
                          width="800"
                          height="730"
                          src="https://jiliko.com.ph/wp-content/uploads/2022/09/ani-cock.png"
                          class="attachment-large size-large"
                          alt
                          srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/ani-cock.png 800w, https://jiliko.com.ph/wp-content/uploads/2022/09/ani-cock-300x274.png 300w, https://jiliko.com.ph/wp-content/uploads/2022/09/ani-cock-768x701.png 768w"
                          sizes="(max-width: 800px) 100vw, 800px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OnlineSabong;

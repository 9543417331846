import React from "react";
import Chooselist from "./Chooselist";
import Bonuses from "./Bonuses";
import "../Home/home.css";
import OnlineSabong from "./OnlineSabong";

const Choose = () => {
  return (
    <div>
      <div class="content-wrap">
        <article
          id="post-71"
          class="entry content-bg single-entry post-71 page type-page status-publish hentry"
        >
          <div class="entry-content-wrap">
            <div class="entry-content single-content">
              <div
                data-elementor-type="wp-page"
                data-elementor-id="71"
                class="elementor elementor-71"
              >
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-6f2a8113 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="6f2a8113"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-614dbe57"
                      data-id="614dbe57"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-dfa01d2 elementor-widget elementor-widget-heading"
                          data-id="dfa01d2"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h3 class="elementor-heading-title elementor-size-default">
                              Why choose jiliko bet Casino?
                            </h3>
                          </div>
                        </div>
                        <Chooselist />
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-b231e4d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="b231e4d"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7d854a3"
                      data-id="7d854a3"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-bc32bce elementor-widget elementor-widget-image"
                          data-id="bc32bce"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <img
                              decoding="async"
                              loading="lazy"
                              width="1024"
                              height="410"
                              src="https://jiliko.com.ph/wp-content/uploads/2023/08/jiliko-welcome-1024x410.webp"
                              class="attachment-large size-large"
                              alt="game"
                              srcset="https://jiliko.com.ph/wp-content/uploads/2023/08/jiliko-welcome-1024x410.webp 1024w, https://jiliko.com.ph/wp-content/uploads/2023/08/jiliko-welcome-300x120.webp 300w, https://jiliko.com.ph/wp-content/uploads/2023/08/jiliko-welcome-768x307.webp 768w, https://jiliko.com.ph/wp-content/uploads/2023/08/jiliko-welcome.webp 1200w"
                              sizes="(max-width: 1024px) 100vw, 1024px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <Bonuses />

                {/* <Join /> */}
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-566778b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="566778b"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-extended">
                    <div
                      class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1f57ba2"
                      data-id="1f57ba2"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-ec60b34 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                          data-id="ec60b34"
                          data-element_type="widget"
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  loading="lazy"
                                  width="450"
                                  height="300"
                                  src="https://jiliko.com.ph/wp-content/uploads/2022/09/促銷活動-4.jpg"
                                  class="attachment-full size-full"
                                  alt="促銷活動-4"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  Lucky draw, 100% get bouns
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fd59e01"
                      data-id="fd59e01"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-90d81f0 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                          data-id="90d81f0"
                          data-element_type="widget"
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  loading="lazy"
                                  width="450"
                                  height="300"
                                  src="https://jiliko.com.ph/wp-content/uploads/2022/09/促銷活動-5.jpg"
                                  class="attachment-full size-full"
                                  alt="促銷活動-5"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  Daily 20% bonus on deposit
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0eb260c"
                      data-id="0eb260c"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-6c54ede elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                          data-id="6c54ede"
                          data-element_type="widget"
                          data-widget_type="image-box.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-box-wrapper">
                              <figure class="elementor-image-box-img">
                                <img
                                  decoding="async"
                                  loading="lazy"
                                  width="450"
                                  height="300"
                                  src="https://jiliko.com.ph/wp-content/uploads/2022/09/促銷活動-6.jpg"
                                  class="attachment-full size-full"
                                  alt="促銷活動-6"
                                />
                              </figure>
                              <div class="elementor-image-box-content">
                                <h5 class="elementor-image-box-title">
                                  Invite friends extra BONUS 10%
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-60b72e32 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="60b72e32"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1268bf9d "
                      data-id="1268bf9d"
                      data-element_type="column"
                      data-settings='{"animation":"fadeIn","animation_delay":300}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-69bdcd92 elementor-widget elementor-widget-heading"
                          data-id="69bdcd92"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h3 class="elementor-heading-title elementor-size-default">
                              Become a member of jiliko in three easy steps
                            </h3>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-3f1767f5 elementor-widget elementor-widget-text-editor"
                          data-id="3f1767f5"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Becoming a member of jiliko.bet is easy, it only
                              takes three minutes to register and you can start
                              playing our games as soon as you complete your
                              registration.
                            </p>
                          </div>
                        </div>
                        <section
                          class="elementor-section elementor-inner-section elementor-element elementor-element-464c7c86 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="464c7c86"
                          data-element_type="section"
                          data-settings='{"background_background":"gradient"}'
                        >
                          <div class="elementor-container elementor-column-gap-default">
                            <div
                              class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-45c013ba"
                              data-id="45c013ba"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-48e88af elementor-widget elementor-widget-heading"
                                  data-id="48e88af"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="elementor-heading-title elementor-size-default">
                                      01
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-14cb18e elementor-widget elementor-widget-heading"
                                  data-id="14cb18e"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <h5 class="elementor-heading-title elementor-size-default">
                                      Click to register
                                    </h5>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-1794ba3 elementor-widget elementor-widget-text-editor"
                                  data-id="1794ba3"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div class="elementor-widget-container">
                                    <p>
                                      Click on our Register button to register
                                      at jiliko login.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2447b85"
                              data-id="2447b85"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-1fd0dba elementor-widget elementor-widget-heading"
                                  data-id="1fd0dba"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="elementor-heading-title elementor-size-default">
                                      02
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-aa35dfa elementor-widget elementor-widget-heading"
                                  data-id="aa35dfa"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <h5 class="elementor-heading-title elementor-size-default">
                                      Fill out the information
                                    </h5>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-df98d88 elementor-widget elementor-widget-text-editor"
                                  data-id="df98d88"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div class="elementor-widget-container">
                                    Simply fill out the required information and
                                    you&#8217;re done.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a9701f8"
                              data-id="a9701f8"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-d0c9e48 elementor-widget elementor-widget-heading"
                                  data-id="d0c9e48"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="elementor-heading-title elementor-size-default">
                                      03
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-8137843 elementor-widget elementor-widget-heading"
                                  data-id="8137843"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <h5 class="elementor-heading-title elementor-size-default">
                                      Become a member
                                    </h5>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-160aa8f elementor-widget elementor-widget-text-editor"
                                  data-id="160aa8f"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div class="elementor-widget-container">
                                    Congratulations, you are now a member and
                                    you can start playing.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div
                          class="elementor-element elementor-element-239e076 elementor-align-left elementor-widget elementor-widget-button"
                          data-id="239e076"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a
                                class="elementor-button elementor-size-md"
                                role="button"
                                href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-text">
                                    Sign up
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-27f33d8 elementor-widget elementor-widget-text-editor"
                          data-id="27f33d8"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            By becoming a jiliko member, you will be able to
                            participate in our new member promotions and receive
                            the best welcome bonuses. For more details on how to
                            sign up, please click on our &#8220;Sign Up
                            Page&#8221;.{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7da6d724"
                      data-id="7da6d724"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-ccc622b elementor-widget elementor-widget-image"
                          data-id="ccc622b"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <img
                              decoding="async"
                              loading="lazy"
                              width="311"
                              height="672"
                              src="https://jiliko.com.ph/wp-content/uploads/2022/09/jiliko-index-mb.jpg"
                              class="attachment-large size-large"
                              alt="Jiliko"
                              srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jiliko-index-mb.jpg 311w, https://jiliko.com.ph/wp-content/uploads/2022/09/jiliko-index-mb-139x300.jpg 139w"
                              sizes="(max-width: 311px) 100vw, 311px"
                            />
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-3fb58f5 elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image-carousel"
                          data-id="3fb58f5"
                          data-element_type="widget"
                          data-settings='{"navigation":"none","slides_to_show":"1","_position":"absolute","autoplay_speed":3000,"effect":"fade","speed":600,"autoplay":"yes","pause_on_hover":"yes","pause_on_interaction":"yes","infinite":"yes"}'
                          data-widget_type="image-carousel.default"
                        >
                          <div class="elementor-widget-container">
                            <div
                              class="elementor-image-carousel-wrapper swiper-container"
                              dir="ltr"
                            >
                              <div class="elementor-image-carousel swiper-wrapper swiper-image-stretch">
                                <div class="swiper-slide">
                                  <figure class="swiper-slide-inner">
                                    <img
                                      decoding="async"
                                      class="swiper-slide-image"
                                      src="https://jiliko.com.ph/wp-content/uploads/2022/09/back_02-1.jpg"
                                      alt="back_02"
                                    />
                                  </figure>
                                </div>
                                <div class="swiper-slide">
                                  <figure class="swiper-slide-inner">
                                    <img
                                      decoding="async"
                                      class="swiper-slide-image"
                                      src="https://jiliko.com.ph/wp-content/uploads/2022/09/back_03-1.jpg"
                                      alt="back_03"
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-012a913 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="012a913"
                  data-element_type="section"
                >
                  <div class="elementor-background-overlay"></div>
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9f9dcb"
                      data-id="b9f9dcb"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <section
                          class="elementor-section elementor-inner-section elementor-element elementor-element-41d375f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="41d375f"
                          data-element_type="section"
                        >
                          <div class="elementor-container elementor-column-gap-default">
                            <div
                              class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-e46cc63"
                              data-id="e46cc63"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-791405b elementor-widget elementor-widget-heading"
                                  data-id="791405b"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">
                                      jiliko has the most popular online slots
                                      games
                                    </h2>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-df91728 elementor-widget elementor-widget-text-editor"
                                  data-id="df91728"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div class="elementor-widget-container">
                                    <p>
                                      jiliko bet aim to bring our players the
                                      ultimate online casino experience. Our
                                      online slots are designed to be
                                      entertaining, fun and offer high payouts
                                      so that you can win big with every spin.
                                      jiliko casino let you enjoy fast-paced
                                      games for free or for real money &#8211;
                                      it’s up to you!
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div
                          class="elementor-element elementor-element-0df5435 elementor-widget elementor-widget-spacer"
                          data-id="0df5435"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-spacer">
                              <div class="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-17bb279 elementor-widget elementor-widget-image-gallery"
                          data-id="17bb279"
                          data-element_type="widget"
                          data-widget_type="image-gallery.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-image-gallery">
                              <div
                                id="gallery-1"
                                class="gallery galleryid-71 gallery-columns-5 gallery-size-thumbnail"
                              >
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-1"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDAsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTEucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        loading="lazy"
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-1-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="casino slot"
                                        decoding="async"
                                        aria-describedby="gallery-1-40"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-1-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-1.png 186w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-40"
                                  >
                                    Totem Wonders
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-3"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDIsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTMucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        loading="lazy"
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-3-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="lucky piggy"
                                        decoding="async"
                                        aria-describedby="gallery-1-42"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-3-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-3.png 186w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-42"
                                  >
                                    Lucky Piggy
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-4"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDMsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTQucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        loading="lazy"
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-4-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Mask Carnival"
                                        decoding="async"
                                        aria-describedby="gallery-1-43"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-4-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-4.png 186w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-43"
                                  >
                                    Mask Carnival
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-5"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDQsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTUucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-5-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Vampire&#8217;s Charm"
                                        decoding="async"
                                        aria-describedby="gallery-1-44"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-5-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-5.png 156w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-44"
                                  >
                                    Vampire&#8217;s Charm
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-6"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDUsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTYucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-6-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Destiny of Sun &amp; Moon"
                                        decoding="async"
                                        aria-describedby="gallery-1-45"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-6-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-6.png 186w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-45"
                                  >
                                    Destiny of Sun &#038; Moon
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-8"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDcsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTgucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-8-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Fortune Tiger"
                                        decoding="async"
                                        aria-describedby="gallery-1-47"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-8-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-8.png 186w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-47"
                                  >
                                    Fortune Tiger
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-9"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDgsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTkucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-9-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="pgslot-9"
                                        decoding="async"
                                        aria-describedby="gallery-1-48"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-9-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-9.png 186w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-48"
                                  >
                                    Prosperity Fortune Tree
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-7"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDYsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTcucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-7-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Mermaid Riches"
                                        decoding="async"
                                        aria-describedby="gallery-1-46"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-7-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-7.png 186w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-46"
                                  >
                                    Mermaid Riches
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-10"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDksInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTEwLnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-10-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="pgslot-10"
                                        decoding="async"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-10-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-10.png 156w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="pgslot-2"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NDEsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvcGdzbG90LTIucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-2-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Wild Bounty Showdown"
                                        decoding="async"
                                        aria-describedby="gallery-1-41"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-2-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/pgslot-2.png 186w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-41"
                                  >
                                    Wild Bounty Showdown
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-1"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NTEsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS0xLnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-1-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="jili-1"
                                        decoding="async"
                                        aria-describedby="gallery-1-51"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-1-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-1.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-51"
                                  >
                                    Medusa
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-2"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzAsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS0yLnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-2-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Book of Gold"
                                        decoding="async"
                                        aria-describedby="gallery-1-30"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-2-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-2.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-30"
                                  >
                                    Book of Gold
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-3"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzEsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS0zLnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-3-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Lucky Lady"
                                        decoding="async"
                                        aria-describedby="gallery-1-31"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-3-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-3.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-31"
                                  >
                                    Lucky Lady
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-4"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzIsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS00LnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-4-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Bone Fortune"
                                        decoding="async"
                                        aria-describedby="gallery-1-32"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-4-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-4.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-32"
                                  >
                                    Bone Fortune
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-6"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzMsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS02LnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-6-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="jili-6"
                                        decoding="async"
                                        aria-describedby="gallery-1-33"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-6-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-6.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-33"
                                  >
                                    Thor X
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-7"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzQsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS03LnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-7-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Happy Taxi"
                                        decoding="async"
                                        aria-describedby="gallery-1-34"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-7-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-7.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-34"
                                  >
                                    Happy Taxi
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-8"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzUsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS04LnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-8-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="Win Drop"
                                        decoding="async"
                                        aria-describedby="gallery-1-35"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-8-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-8.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-35"
                                  >
                                    Win Drop
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-9-2"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzgsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS05LTIucG5nIiwic2xpZGVzaG93IjoiMTdiYjI3OSJ9"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-9-2-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="JILI CAISHEN"
                                        decoding="async"
                                        aria-describedby="gallery-1-38"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-9-2-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-9-2.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-38"
                                  >
                                    JILI CAISHEN
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-9"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzYsInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS05LnBuZyIsInNsaWRlc2hvdyI6IjE3YmIyNzkifQ%3D%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-9-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="casino philippines"
                                        decoding="async"
                                        aria-describedby="gallery-1-36"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-9-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-9.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-36"
                                  >
                                    Mega Ace
                                  </figcaption>
                                </figure>
                                <figure class="gallery-item">
                                  <div class="gallery-icon landscape">
                                    <a
                                      data-elementor-open-lightbox="yes"
                                      data-elementor-lightbox-slideshow="17bb279"
                                      data-elementor-lightbox-title="jili-10"
                                      e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MzksInVybCI6Imh0dHBzOlwvXC9qaWxpa28uY29tLnBoXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDIyXC8wOVwvamlsaS0xMC5wbmciLCJzbGlkZXNob3ciOiIxN2JiMjc5In0%3D"
                                      href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                    >
                                      <img
                                        width="150"
                                        height="150"
                                        src="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-10-150x150.png"
                                        class="attachment-thumbnail size-thumbnail"
                                        alt="casino philippines"
                                        decoding="async"
                                        aria-describedby="gallery-1-39"
                                        loading="lazy"
                                        srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/jili-10-150x150.png 150w, https://jiliko.com.ph/wp-content/uploads/2022/09/jili-10.png 300w"
                                        sizes="(max-width: 150px) 100vw, 150px"
                                      />
                                    </a>
                                  </div>
                                  <figcaption
                                    class="wp-caption-text gallery-caption"
                                    id="gallery-1-39"
                                  >
                                    Mayan Empire
                                  </figcaption>
                                </figure>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-6cc9da5 elementor-align-center elementor-widget elementor-widget-button"
                          data-id="6cc9da5"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a
                                href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                class="elementor-button-link elementor-button elementor-size-md"
                                role="button"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-text">
                                    See More
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <OnlineSabong />

                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-226947b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="226947b"
                  data-element_type="section"
                >
                  <div class="elementor-background-overlay"></div>
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0ff671b"
                      data-id="0ff671b"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <section
                          class="elementor-section elementor-inner-section elementor-element elementor-element-8e20fe5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="8e20fe5"
                          data-element_type="section"
                        >
                          <div class="elementor-container elementor-column-gap-default">
                            <div
                              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ca0d4ce"
                              data-id="ca0d4ce"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-5859692 elementor-widget elementor-widget-heading"
                                  data-id="5859692"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default">
                                      jiliko other interesting games
                                    </h3>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-68f52db elementor-widget elementor-widget-text-editor"
                                  data-id="68f52db"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div class="elementor-widget-container">
                                    <p>
                                      At jiliko login, our slot games and sabong
                                      betting are our main offerings, in
                                      addition to these games players can find
                                      below.
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-8529732 elementor-widget elementor-widget-image"
                                  data-id="8529732"
                                  data-element_type="widget"
                                  data-widget_type="image.default"
                                >
                                  <div class="elementor-widget-container">
                                    <img
                                      decoding="async"
                                      loading="lazy"
                                      width="547"
                                      height="522"
                                      src="https://jiliko.com.ph/wp-content/uploads/2022/09/home-img4.png"
                                      class="attachment-large size-large"
                                      alt="gamesss"
                                      srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/home-img4.png 547w, https://jiliko.com.ph/wp-content/uploads/2022/09/home-img4-300x286.png 300w"
                                      sizes="(max-width: 547px) 100vw, 547px"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-63bbbc1"
                              data-id="63bbbc1"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <section
                                  class="elementor-section elementor-inner-section elementor-element elementor-element-8a047c5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                  data-id="8a047c5"
                                  data-element_type="section"
                                >
                                  <div class="elementor-container elementor-column-gap-default">
                                    <div
                                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7e1052a"
                                      data-id="7e1052a"
                                      data-element_type="column"
                                    >
                                      <div class="elementor-widget-wrap elementor-element-populated">
                                        <div
                                          class="elementor-element elementor-element-3bfc679 elementor-widget elementor-widget-heading"
                                          data-id="3bfc679"
                                          data-element_type="widget"
                                          data-widget_type="heading.default"
                                        >
                                          <div class="elementor-widget-container">
                                            <h4 class="elementor-heading-title elementor-size-default">
                                              Sports Betting
                                            </h4>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-element elementor-element-2f55ced elementor-widget elementor-widget-text-editor"
                                          data-id="2f55ced"
                                          data-element_type="widget"
                                          data-widget_type="text-editor.default"
                                        >
                                          <div class="elementor-widget-container">
                                            <p>
                                              Place your bets on sporting events
                                              from all over the world at jiliko
                                              casino. Sports betting is a great
                                              way to get involved with your
                                              favorite team while making money,
                                              and we are here to provide you
                                              with the best sports betting
                                              experience.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f3b8f5c"
                                      data-id="f3b8f5c"
                                      data-element_type="column"
                                    >
                                      <div class="elementor-widget-wrap elementor-element-populated">
                                        <div
                                          class="elementor-element elementor-element-e86cd06 elementor-widget elementor-widget-heading"
                                          data-id="e86cd06"
                                          data-element_type="widget"
                                          data-widget_type="heading.default"
                                        >
                                          <div class="elementor-widget-container">
                                            <h4 class="elementor-heading-title elementor-size-default">
                                              Live Games
                                            </h4>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-element elementor-element-32cffc5 elementor-widget elementor-widget-text-editor"
                                          data-id="32cffc5"
                                          data-element_type="widget"
                                          data-widget_type="text-editor.default"
                                        >
                                          <div class="elementor-widget-container">
                                            At jiliko live casino, you will find
                                            everything you need to experience
                                            the thrill of real life gambling.
                                            With our beautiful dealers, play
                                            your favourite games on our stunning
                                            and immersive tables in real-time,
                                            it couldn’t be easier to find your
                                            favourite game.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                                <section
                                  class="elementor-section elementor-inner-section elementor-element elementor-element-57c3ae6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                  data-id="57c3ae6"
                                  data-element_type="section"
                                >
                                  <div class="elementor-container elementor-column-gap-default">
                                    <div
                                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0ba6d99"
                                      data-id="0ba6d99"
                                      data-element_type="column"
                                    >
                                      <div class="elementor-widget-wrap elementor-element-populated">
                                        <div
                                          class="elementor-element elementor-element-d12f6f1 elementor-widget elementor-widget-heading"
                                          data-id="d12f6f1"
                                          data-element_type="widget"
                                          data-widget_type="heading.default"
                                        >
                                          <div class="elementor-widget-container">
                                            <h4 class="elementor-heading-title elementor-size-default">
                                              Fish Games
                                            </h4>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-element elementor-element-e6f9b47 elementor-widget elementor-widget-text-editor"
                                          data-id="e6f9b47"
                                          data-element_type="widget"
                                          data-widget_type="text-editor.default"
                                        >
                                          <div class="elementor-widget-container">
                                            This is one of the most addicting
                                            games you could ever play, and it
                                            only gets better with time. All you
                                            have to do is shoot the fish targets
                                            that appear on the screen and if you
                                            defeat them, you&#8217;ll receive a
                                            bonus. This game you can enjoy
                                            endlessly!{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9264c34"
                                      data-id="9264c34"
                                      data-element_type="column"
                                    >
                                      <div class="elementor-widget-wrap elementor-element-populated">
                                        <div
                                          class="elementor-element elementor-element-e0ff2f2 elementor-widget elementor-widget-heading"
                                          data-id="e0ff2f2"
                                          data-element_type="widget"
                                          data-widget_type="heading.default"
                                        >
                                          <div class="elementor-widget-container">
                                            <h4 class="elementor-heading-title elementor-size-default">
                                              Arcade Games
                                            </h4>
                                          </div>
                                        </div>
                                        <div
                                          class="elementor-element elementor-element-00c594e elementor-widget elementor-widget-text-editor"
                                          data-id="00c594e"
                                          data-element_type="widget"
                                          data-widget_type="text-editor.default"
                                        >
                                          <div class="elementor-widget-container">
                                            <p>
                                              When it comes to casino table
                                              games, you won’t find any better
                                              than what is offered at
                                              jiliko.bet. We offer the most
                                              affordable and best value for
                                              money options for all your table
                                              games. You can play special table
                                              games such as Sic Bo, 7up7down,
                                              Color Dish, etc.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div
                          class="elementor-element elementor-element-b7b2d4a elementor-widget elementor-widget-text-editor"
                          data-id="b7b2d4a"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              At jiliko casino, our slot games and sabong
                              betting are our main offerings, in addition to
                              these games players can find below.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-97f4fde elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="97f4fde"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-55f9b61"
                      data-id="55f9b61"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-41b7459 elementor-widget elementor-widget-image"
                          data-id="41b7459"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <img
                              decoding="async"
                              loading="lazy"
                              width="787"
                              height="653"
                              src="https://jiliko.com.ph/wp-content/uploads/2022/09/gaming.png"
                              class="attachment-large size-large"
                              alt="imagesss"
                              srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/gaming.png 787w, https://jiliko.com.ph/wp-content/uploads/2022/09/gaming-300x249.png 300w, https://jiliko.com.ph/wp-content/uploads/2022/09/gaming-768x637.png 768w"
                              sizes="(max-width: 787px) 100vw, 787px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f3ba07c"
                      data-id="f3ba07c"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-7df1b23 elementor-widget elementor-widget-heading"
                          data-id="7df1b23"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h3 class="elementor-heading-title elementor-size-default">
                              JILIKO offers the best online casino experience
                            </h3>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-e1113cd elementor-widget elementor-widget-text-editor"
                          data-id="e1113cd"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            At jiliko casino, we offer you a wide choice of
                            casino games. All of our providers are well-known
                            and trusted, such as PG slot, JILI, JDB, KA Gaming,
                            CQ9, BNG, playstar, Rich88, Sexy Gaming, Dream
                            Gaming, WM Gaming, Evolution Gaming. Each of these
                            are renowned for quality and fairness, so that
                            players can enjoy a perfect gaming experience at
                            jiliko casino.{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-7d60513 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="7d60513"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1126c94 "
                      data-id="1126c94"
                      data-element_type="column"
                      data-settings='{"animation":"fadeIn","animation_delay":300}'
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-61981a1 elementor-widget elementor-widget-heading"
                          data-id="61981a1"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h3 class="elementor-heading-title elementor-size-default">
                              Download JILIKO app, it's easy and fast
                            </h3>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-db60fb5 elementor-widget elementor-widget-text-editor"
                          data-id="db60fb5"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            Download the jiliko mobile app and start playing
                            your favourite online casino games whenever and
                            wherever you want. The easiest way to get started is
                            to download the app, log in or register and
                            you&#8217;re good to go. You can play games on
                            desktop, tablet or smartphone; we support Android
                            and iOS systems. You&#8217;ll have access to all
                            your favorite games and the best bonuses!{" "}
                          </div>
                        </div>
                        <section
                          class="elementor-section elementor-inner-section elementor-element elementor-element-f2ea834 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="f2ea834"
                          data-element_type="section"
                        >
                          <div class="elementor-container elementor-column-gap-default">
                            <div
                              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-457ad43"
                              data-id="457ad43"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-560e30e elementor-align-left elementor-widget elementor-widget-button"
                                  data-id="560e30e"
                                  data-element_type="widget"
                                  data-widget_type="button.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="elementor-button-wrapper">
                                      <a
                                        class="elementor-button elementor-size-md"
                                        role="button"
                                        href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                      >
                                        <span class="elementor-button-content-wrapper">
                                          <span class="elementor-button-text">
                                            iOS APP Download
                                          </span>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2e12c4b"
                              data-id="2e12c4b"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-5aba876 elementor-align-left elementor-widget elementor-widget-button"
                                  data-id="5aba876"
                                  data-element_type="widget"
                                  data-widget_type="button.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="elementor-button-wrapper">
                                      <a
                                        class="elementor-button elementor-size-md"
                                        role="button"
                                        href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                      >
                                        <span class="elementor-button-content-wrapper">
                                          <span class="elementor-button-text">
                                            Android APP Download
                                          </span>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5779b30"
                      data-id="5779b30"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-0f64b99 elementor-widget elementor-widget-image"
                          data-id="0f64b99"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div class="elementor-widget-container">
                            <img
                              decoding="async"
                              loading="lazy"
                              width="774"
                              height="1024"
                              src="https://jiliko.com.ph/wp-content/uploads/2022/09/47-774x1024.png"
                              class="attachment-large size-large"
                              alt="download jiliko app"
                              srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/47-774x1024.png 774w, https://jiliko.com.ph/wp-content/uploads/2022/09/47-227x300.png 227w, https://jiliko.com.ph/wp-content/uploads/2022/09/47-768x1017.png 768w, https://jiliko.com.ph/wp-content/uploads/2022/09/47.png 800w"
                              sizes="(max-width: 774px) 100vw, 774px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-cf243bd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="cf243bd"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bea5b19"
                      data-id="bea5b19"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-e565e67 elementor-widget elementor-widget-heading"
                          data-id="e565e67"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h3 class="elementor-heading-title elementor-size-default">
                              Download the app and claim your bonuses
                            </h3>
                          </div>
                        </div>
                        <section
                          class="elementor-section elementor-inner-section elementor-element elementor-element-2d3a498 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="2d3a498"
                          data-element_type="section"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div class="elementor-container elementor-column-gap-default">
                            <div
                              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ec5d5dd elementor-hidden-tablet elementor-hidden-phone"
                              data-id="ec5d5dd"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-62f0ef4 elementor-widget elementor-widget-image"
                                  data-id="62f0ef4"
                                  data-element_type="widget"
                                  data-widget_type="image.default"
                                >
                                  <div class="elementor-widget-container">
                                    <img
                                      decoding="async"
                                      loading="lazy"
                                      width="450"
                                      height="300"
                                      src="https://jiliko.com.ph/wp-content/uploads/2022/09/促銷活動-7.jpg"
                                      class="attachment-large size-large"
                                      alt="促銷活動"
                                      srcset="https://jiliko.com.ph/wp-content/uploads/2022/09/促銷活動-7.jpg 450w, https://jiliko.com.ph/wp-content/uploads/2022/09/促銷活動-7-300x200.jpg 300w"
                                      sizes="(max-width: 450px) 100vw, 450px"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1819412"
                              data-id="1819412"
                              data-element_type="column"
                            >
                              <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                  class="elementor-element elementor-element-469e527 elementor-widget elementor-widget-heading"
                                  data-id="469e527"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <h5 class="elementor-heading-title elementor-size-default">
                                      New member download the app get Jili Free
                                      100 Bonus
                                    </h5>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-44fe423 elementor-widget elementor-widget-text-editor"
                                  data-id="44fe423"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div class="elementor-widget-container">
                                    Once you download our app, you can claim
                                    your download bonus{" "}
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-9b64fd3 elementor-align-left elementor-widget elementor-widget-button"
                                  data-id="9b64fd3"
                                  data-element_type="widget"
                                  data-widget_type="button.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="elementor-button-wrapper">
                                      <a
                                        class="elementor-button elementor-size-md"
                                        role="button"
                                        href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                      >
                                        <span class="elementor-button-content-wrapper">
                                          <span class="elementor-button-text">
                                            Get bonuses
                                          </span>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-5bbe0910 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="5bbe0910"
                  data-element_type="section"
                  data-settings='{"background_background":"classic"}'
                >
                  <div class="elementor-background-overlay"></div>
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-21a13d97"
                      data-id="21a13d97"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-1394e75 elementor-widget elementor-widget-heading"
                          data-id="1394e75"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h3 class="elementor-heading-title elementor-size-default">
                              A safe, secure and fun gaming experience
                            </h3>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-493f4146 elementor-widget elementor-widget-text-editor"
                          data-id="493f4146"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              JILIKO bet is one of the leading online casinos in
                              the world. We have carefully hand-selected all of
                              our games, making sure that you can enjoy some of
                              the best online casino services. Our mission is to
                              offer you only the best gaming environment when
                              you decide to play at our casino.
                            </p>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-cebe15a elementor-align-center elementor-widget elementor-widget-button"
                          data-id="cebe15a"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a
                                href="https://bj88ph.live/af/Zd946zCP/jiliko-game"
                                class="elementor-button-link elementor-button elementor-size-md"
                                role="button"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-text">
                                    Get Started
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Choose;
